<template>
  <div class="cate-container">
    <CardCategory :cates="cates" />
  </div>
</template>

<script>
import CardCategory from '@/components/CardCategory'
import { getSubCategorty } from '@/api/home'
export default {
  data () {
    return {
      cates: []
    }
  },
  components: {
    CardCategory
  },
  mounted () {
    document.title = 'تۇرلەر'
    this.queryCateData(this.$route.params.id)
  },
  methods: {
    async queryCateData (id) {
      const res = await getSubCategorty(id)
      if (res.errorCode === 0) this.cates = res.data.sub
    }
  }
}
</script>

<style lang="less" scoped>
.cate-container {
  height: 100vh;
  background-color: #fff;
}
</style>
